import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/Index.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/service/Index.vue')
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import('@/views/case/Index.vue')
  },
  {
    path: '/case',
    component: () => import('@/views/case/Base.vue'),
    children: [
      { path: 'case01', name:'Case01', component: () => import('@/views/case/Case01.vue') },
      { path: 'case02', name:'Case02', component: () => import('@/views/case/Case02.vue') },
      { path: 'case03', name:'Case03', component: () => import('@/views/case/Case03.vue') },
      { path: 'case04', name:'Case04', component: () => import('@/views/case/Case04.vue') },
      { path: 'case05', name:'Case05', component: () => import('@/views/case/Case05.vue') },
      { path: 'case06', name:'Case06', component: () => import('@/views/case/Case06.vue') },
      { path: 'case07', name:'Case07', component: () => import('@/views/case/Case07.vue') },
      { path: 'case08', name:'Case08', component: () => import('@/views/case/Case08.vue') },
    ]
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/product/Index.vue')
  },
  {
    path: '/product/product01',
    name: 'Product01',
    component: () => import('@/views/product/Product01.vue')
  },
  {
    path: '/product/product02',
    name: 'Product02',
    component: () => import('@/views/product/Product02.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/contact/Index.vue')
  },
  {
    path: '/contact/thanks',
    name: 'Thanks',
    component: () => import('@/views/contact/Thanks.vue')
  },
  {
    path: '/contact_simulation',
    name: 'ContactSimulation',
    component: () => import('@/views/contactSimulation/Index.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/download/Index.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: () => import('@/views/Notfound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior (to, from, savedPosition) {
  scrollBehavior() {
    const selector = location.hash;
    let y = 0;
    if (selector) {
      y = document.querySelector(selector).offsetTop-20;
      if(document.body.offsetWidth<=768) y =document.querySelector(selector).offsetTop-70;
    }
    return { x: 0, y: y };
    // return { x: 0, y: 0 } //vue3だとtop、left
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   return { x: 0, y: 0 } //vue3だとtop、left
    // }
  }
})

export default router
